import React from 'react'

import { readFile } from 'utils/fs-helpers'

import { generateFileWithPath } from 'api/path'
import { getOptions } from 'api/options'
import { getPageData } from 'api/page'
import { getEntity, } from 'api/entity'
import { getBrandData } from 'api/brand'

import {
  generateHref,
  normalizeData,
  normalizeMetadata,
} from 'api/utils'

import Layout from 'layouts/default'
import SectionBuilder from '@/organisms/SectionBuilder'

export const getStaticPaths = async () => {
  const pagesCache = await generateFileWithPath()

  const paths = pagesCache.map(({
    id, slug
  }) => ({
    params: {
      id, slug
    }
  }))
  return {
    paths: paths,
    fallback: false
  }
}

export const getStaticProps = async ({ params }) => {
  const cacheFile = await readFile('static-paths.json', 'utf-8')
  const staticPaths = JSON.parse(cacheFile)
  const {
    id,
    type,
    isHomepage,
    isMainBrand,
    mainBrandId,
    brand,
    slug,
  } = staticPaths.find((path) => {
    if (params.slug) {
      const pathSlug = Array.isArray(path.slug) ? path.slug.join('/') :  path.slug
      return  pathSlug === params.slug.join('/')
    }

    return path.isMainBrand && path.isHomepage
  })
  console.time(`Entity: ${type} - ${id}`)

  const [
    brands,
    page
  ] = await Promise.all([
    getEntity({
      entity: 'brands',
      params: { _fields: 'id,type,title,slug,header,footer,acf.favicon,acf.homepage,acf.isVertical,acf.logoHeader,acf.logoFooter,acf.socials' }
    }),
    getPageData({
      id,
      type,
      params: { _fields: 'type,title,link,breadcrumbs,acf,yoast_head,slug,status,featured_media' }
    })
  ])

  const [
    brandData = {},
    options
  ] = await Promise.all([
    getBrandData(brand, brands, mainBrandId),
    getOptions()
  ])

  if (!page.data?.status === 404) return false

  const sections =  await normalizeData(page)
  const metadata = await normalizeMetadata(page)
  const {
    current,
    others,
  } = brandData

  const {
    headerMenu = null,
    footerMenu = null,
    favicon = null,
    ...brandRest
  } = current
  console.timeEnd(`Entity: ${type} - ${id}`)

  return {
    props: {
      brand: brandRest.slug || null,
      title: page.title,
      metadata: {
        ...metadata,
        favicon,
      },
      options: {
        isHomepage,
        isMainBrand,
        mainBrandId,
        brand: {
          id: brand ? brand.id : null,
          ...brandRest
        },
        otherBrands: others,
        ...options
      },
      additionalData: {
        brand: brandRest.slug || null,
        currentPageSlug: slug,
        currentPageLink: generateHref(slug),
        showBackToTop: page.acf.lastSectionArrow,
      },
      sections,
      menus: {
        headerMenu,
        footerMenu
      }
    }
  }
}

export default function Page({
  sections,
  metadata,
}) {
  return (
    <Layout metadata={metadata}>
      <SectionBuilder data={sections} />
    </Layout>
  )
}
